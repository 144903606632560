export default [
  {
    header: 'Master Data',
    resource: 'Master',
  },
  {
    title: 'Toko Bangunan',
    route: 'toko-master',
    icon: 'FileTextIcon',
    resource: 'Master',
    action: 'manage',
  },
  {
    title: 'Customer',
    route: 'customer-master',
    icon: 'UsersIcon',
    resource: 'Master',
    action: 'manage',
  },
  {
    title: 'Harga Produk',
    route: 'price-master',
    icon: 'DollarSignIcon',
    resource: 'Master',
    action: 'manage',
  },
]
