export default [
  {
    header: 'Transaksi',
    resource: 'Sales',
  },
  {
    title: 'Data Transaksi',
    route: 'list-sale',
    icon: 'FileTextIcon',
    resource: 'Sales',
    action: 'manage',
  },
]
